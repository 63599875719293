html {
    background-color: black;
  }
  
  .cyber-title {
    color: white;
    font-family: Barlow, sans-serif;
  }
  
  :root {
    --blue-color: #607aa6; 
    --red-color: #b5053b;
    --maroon-color: #331517;
    --teal-color: #276d6c;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8vh;
  }
  
  .btn {
    width: 180px;
    height: 49px;
    border: 0;
    outline: none;
    padding: 20px 25px;
    position: relative;
    background-color:whitesmoke;
    cursor: pointer;
    font-family: Tomorrow, sans-serif;
    font-size: .85rem;
    text-transform: uppercase;
    color: black;
    clip-path: polygon(92% 0, 100% 25%, 100% 100%, 8% 100%, 0% 75%, 0 0);
  }
  
  .btn__content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    background-color: var(--teal-color);
    clip-path: polygon(92% 0, 100% 25%, 100% 100%, 8% 100%, 0% 75%, 0 0);
  }
  
  .btn__glitch {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--yellow-color);
    filter: drop-shadow(-2px 3px red) drop-shadow(-1px -3px red) drop-shadow(2px 1px red);
  }
  
  .btn__glitch {
    background-color: var(--red-color);
  }
  
  .btn:hover .btn__glitch,
  .btn:hover .btn__content::after,
  .btn:focus .btn__glitch,
  .btn:focus .btn__content::after {
    display: block;
    animation: glitch-animation 2s linear 0s infinite;
  }
  
  /* secret trick */
  @keyframes glitch-animation {
    0% {
      opacity: 1;
      transform: translateZ(0);
      clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    }
  
    2% {
      clip-path: polygon(0 78%, 100% 78%, 100% 100%, 0 100%);
      transform: translate(-5px);
    }
  
    6% {
      clip-path: polygon(0 78%, 100% 78%, 100% 100%, 0 100%);
      transform: translate(5px);
    }
  
    8% {
      clip-path: polygon(0 78%, 100% 78%, 100% 100%, 0 100%);
      transform: translate(-5px);
    }
  
    9% {
      clip-path: polygon(0 78%, 100% 78%, 100% 100%, 0 100%);
      transform: translate(0);
    }
  
    10% {
      clip-path: polygon(0 54%, 100% 54%, 100% 44%, 0 44%);
      transform: translate3d(5px, 0, 0);
    }
  
    13% {
      clip-path: polygon(0 54%, 100% 54%, 100% 44%, 0 44%);
      transform: translateZ(0);
    }
  
    13.1% {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      transform: translate3d(5px, 0, 0);
    }
  
    15% {
      clip-path: polygon(0 60%, 100% 60%, 100% 40%, 0 40%);
      transform: translate3d(5px, 0, 0);
    }
  
    20% {
      clip-path: polygon(0 60%, 100% 60%, 100% 40%, 0 40%);
      transform: translate3d(-5px, 0, 0);
    }
  
    20.1% {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      transform: translate3d(5px, 0, 0);
    }
  
    25% {
      clip-path: polygon(0 85%, 100% 85%, 100% 40%, 0 40%);
      transform: translate3d(5px, 0, 0);
    }
  
    30% {
      clip-path: polygon(0 85%, 100% 85%, 100% 40%, 0 40%);
      transform: translate3d(-5px, 0, 0);
    }
  
    30.1% {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
  
    35% {
      clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
      transform: translate(-5px);
    }
  
    40% {
      clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
      transform: translate(5px);
    }
  
    45% {
      clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
      transform: translate(-5px);
    }
  
    50% {
      clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
      transform: translate(0);
    }
  
    55% {
      clip-path: polygon(0 10%, 100% 10%, 100% 0, 0 0);
      transform: translate3d(5px, 0, 0);
    }
  
    60% {
      clip-path: polygon(0 10%, 100% 10%, 100% 0, 0 0);
      transform: translateZ(0);
      opacity: 1;
    }
  
    60.1% {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      opacity: 1;
    }
  
    to {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      opacity: 1;
    }
  }
  
  .cyber-container {
    width: 100%;
  }
  
  .h-container {
    margin-top: 42vh;
  }
  
  .glitch {
    text-align: center;
    color: #fff;
    font-size: medium;
    font-weight: 400;
    letter-spacing: 1px;

    /*Create overlap*/
    margin: 0;
    line-height: 0;

    /*Animation*/
    animation: glitch1 5.0s infinite;
  }
  
  .glitch:nth-child(2) {
    color: #67f3da;
    animation: glitch2 5.0s infinite;
  }
  
  .glitch:nth-child(3) {
    color: #f16f6f;
    animation: glitch3 5.0s infinite;
  }
  /*Keyframes*/
  
  @keyframes glitch1 {
    0% {
      transform: none;
      opacity: 1;
    }
    7% {
      transform: skew(-0.5deg, -0.9deg);
      opacity: 0.75;
    }
    10% {
      transform: none;
      opacity: 1;
    }
    27% {
      transform: none;
      opacity: 1;
    }
    30% {
      transform: skew(0.8deg, -0.1deg);
      opacity: 0.75;
    }
    35% {
      transform: none;
      opacity: 1;
    }
    52% {
      transform: none;
      opacity: 1;
    }
    55% {
      transform: skew(-1deg, 0.2deg);
      opacity: 0.75;
    }
    50% {
      transform: none;
      opacity: 1;
    }
    72% {
      transform: none;
      opacity: 1;
    }
    75% {
      transform: skew(0.4deg, 1deg);
      opacity: 0.75;
    }
    80% {
      transform: none;
      opacity: 1;
    }
    100% {
      transform: none;
      opacity: 1;
    }
  }
  
  @keyframes glitch2 {
    0% {
      transform: none;
      opacity: 0.25;
    }
    7% {
      transform: translate(-2px, -3px);
      opacity: 0.5;
    }
    10% {
      transform: none;
      opacity: 0.25;
    }
    27% {
      transform: none;
      opacity: 0.25;
    }
    30% {
      transform: translate(-5px, -2px);
      opacity: 0.5;
    }
    35% {
      transform: none;
      opacity: 0.25;
    }
    52% {
      transform: none;
      opacity: 0.25;
    }
    55% {
      transform: translate(-5px, -1px);
      opacity: 0.5;
    }
    50% {
      transform: none;
      opacity: 0.25;
    }
    72% {
      transform: none;
      opacity: 0.25;
    }
    75% {
      transform: translate(-2px, -6px);
      opacity: 0.5;
    }
    80% {
      transform: none;
      opacity: 0.25;
    }
    100% {
      transform: none;
      opacity: 0.25;
    }
  }
  
  @keyframes glitch3 {
    0% {
      transform: none;
      opacity: 0.25;
    }
    7% {
      transform: translate(2px, 3px);
      opacity: 0.5;
    }
    10% {
      transform: none;
      opacity: 0.25;
    }
    27% {
      transform: none;
      opacity: 0.25;
    }
    30% {
      transform: translate(5px, 2px);
      opacity: 0.5;
    }
    35% {
      transform: none;
      opacity: 0.25;
    }
    52% {
      transform: none;
      opacity: 0.25;
    }
    55% {
      transform: translate(5px, 1px);
      opacity: 0.5;
    }
    50% {
      transform: none;
      opacity: 0.25;
    }
    72% {
      transform: none;
      opacity: 0.25;
    }
    75% {
      transform: translate(2px, 6px);
      opacity: 0.5;
    }
    80% {
      transform: none;
      opacity: 0.25;
    }
    100% {
      transform: none;
      opacity: 0.25;
    }
  }

  /* 800px and above; desktops */
  @media screen and (min-width: 800px) { 
    .btn {
      width: 230px;
      height: 60px; 
    }

    .glitch {
      font-size: 5em;
      letter-spacing: 8px;
    }
  }

  /* 500px and above; tablets */
  @media screen and (min-width: 500px) { 
    .glitch {
      font-size: xx-large;
      letter-spacing: 5px;
    }
  }