:root {
    --blue-color: #607aa6; 
    --red-color: #b5053b;
    --maroon-color: #331517;
    --teal-color: #276d6c;
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: -10;
  background-color: black;
}

.container {
  display: flex;
  height: auto;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  animation: expand .8s ease forwards;
  background-color: black;
  position: relative;
  font-family: Barlow, sans-serif;
  transition: all .8s ease;
}

.container_content {
 width: 50%;
}

.container_content_inner {
  width: 80%;
  margin-left: 80px;
}

.projects-container-outer-img {
  margin-right: 10px;
  margin-left: 10px;
}   
    
.container_img {
  width: 80%;
  animation: slideIn 1.5s ease-in-out forwards;
}

.par {
  height: auto;
  overflow: hidden;
  margin-bottom: 25px;
}

.summary {
  color: var(--blue-color);
  line-height: 28px;
  transform: translateY(300px);
  animation: slideIn .8s ease-in-out forwards .8s;
}

.btns {
  height: 100%;
  position: relative;
  width: 150px;
  overflow: hidden;
}

.btns_more {
  background: transparent; 
  padding: 8px 12px;
  color: var(--red-color);
  font-size: 16px;
  border:none;
  text-transform: lowercase;
  position: relative;
  margin-top: 15px;
  outline: none;
  transform: translateY(50px);
  animation: slideUp .8s ease-in-out  forwards 1s;

  --aug-border: 5px;
  --aug-inset: 5px;
  padding: 12px; /* mind the gap */
  text-align: center;
  --aug-border-bg:linear-gradient(#b5053b, transparent), linear-gradient(to right, #276d6c, transparent), #276d6c;
}

a.project-icon-button:hover {
  color: var(--blue-color);
}

.icon-animation {
  transform: translateY(50px);
  animation: slideUp .8s ease-in-out  forwards 1s;
}

.flip-container {
  margin: 5px;
}

.project-title {
 color: var(--red-color);
 font-weight: bold;
}

.project-header {
    font-size: 40px;
    color: var(--teal-color);
    margin-bottom: 20px;
    transform: translateY(100px);
    animation: slideIn .8s ease forwards .5s;
}

.link {
  padding-left: 10px;
  font-weight: bold;
  color: var(--red-color)
}

.project-img {
  width: 100%;
}

#footer {
  margin: 55px;
  margin-top: 30px;
  text-align:center;
  color: var(--teal-color);
  font-size: 25px;
  font-weight: bold;
  border:none;
  text-transform: lowercase;
  position: relative;
  outline: none;
  transform: translateY(50px);
  animation: slideUp .8s ease-in-out  forwards 1s;
}

.footer-nav {
  margin-top: 15px;
}


.project-icon-button {
  color: var(--red-color);
  margin:20px;
  font-weight: bold;
  position: relative;
  transform: translateY(50px);
  animation: slideUp .8s ease-in-out  forwards 1s;
}

@keyframes slideIn {
  0% {
    transform: translateX(500px) scale(.2);
  }
  100% {
    transform: translateX(0px) scale(1);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(300px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes expand {
  0% {
    transform: translateX(1400px);
  }
  100% {
    transform: translateX(0px);
  }
}

/* 700px and above; ipad */

@media screen and (min-width: 700px) {
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: -10;
  }
  
  .projects-container {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    color: #fff;
    animation: expand .8s ease forwards;
    background-color: black;
    position: relative;
    font-family: Barlow, sans-serif;
    transition: all .8s ease;
  }
  
  .container_content {
   width: 50%;
  }
  
  .container_content_inner {
    width: 80%;
    margin-left: 80px;
  }

  .projects-img-inner {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .projects-container-outer-img {
    margin: 20px;
    width: 44%;
    overflow: hidden;
    display: inline-block;
    --aug-border: 5px;
    --aug-inset: 5px;
    padding: 60px; /* mind the gap */
    padding-top: 0;
    text-align: center;
    --aug-border-bg:linear-gradient(#b5053b, transparent), linear-gradient(to right, #276d6c, transparent), #276d6c;
  }   
      
  .container_img {
    width: 80%;
    animation: slideIn 1.5s ease-in-out forwards;
  }
  
  .par {
    height: auto;
    overflow: hidden;
  }
  
  p{
    line-height: 28px;
    transform: translateY(300px);
    animation: slideUp .8s ease-in-out forwards .8s;
  }
  
  .btns {
    height: 100%;
    position: relative;
    width: 150px;
    overflow: hidden;
  }
  
  .btns_more {
    background: transparent; 
    padding: 8px 12px;
    color: #BF307F;
    font-size: 16px;
    border:none;
    text-transform: lowercase;
    position: relative;
    margin-top: 15px;
    outline: none;
    transform: translateY(50px);
    animation: slideUp .8s ease-in-out  forwards 1s;

    --aug-border: 5px;
    --aug-inset: 5px;
    padding: 12px; /* mind the gap */
    text-align: center;
    --aug-border-bg:linear-gradient(#b5053b, transparent), linear-gradient(to right, #276d6c, transparent), #276d6c;
  }
  
  .title {
    overflow: hidden;
    height: auto;
  }
  
  .project-header {
      font-size: 65px;
      /* color: var(--teal-color); */
      margin-bottom: 20px;
      transform: translateY(100px);
      animation: slideUp .8s ease forwards .5s;
  }

  .link .project-title {
    font-weight: bolder;
  }

  .footer-nav {
    margin-top: 25px;
  }



  /* css fun stuff */
  .flip-container {
    perspective: 1000px;
  }
    /* flip the pane when hovered */
    .flip-container:hover .flipper, .flip-container.hover .flipper {
      transform: rotateY(180deg);
    }
  
  .flip-container  {
    width: 100%;
    height: 20vh;
  }
  
  .front.digi-img {
    margin-top: 50px;
  }

  .back.digi-back {
    margin: -5px;
  }
  
  .back {
    margin: 10px;
  }
  
  .tallyho-desc.back {
    margin: -31px;
  }
  
  /* flip speed goes here */
  .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
  
    position: relative;
  }
  
  /* hide back of pane during swap */
  .front, .back {
    backface-visibility: hidden;
  
    position: absolute;
    top: 0;
    left: 0;
  }
  
  /* front pane, placed above back */
  .front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg);
  }
  
  /* back, initially hidden pane */
  .back {
    transform: rotateY(180deg);
  }
  
  .project-img {
    width: 60%;
  }
}

/* 800px and above; desktops */

@media screen and (min-width: 1030px) {
  
  .project-header {
    margin-left: 20px;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: -10;
  }
  
  .projects-container {
    display: flex;
    height: auto;
    justify-content: space-around;
    flex-direction: unset;
    align-items: center;
    color: #fff;
    animation: expand .8s ease forwards;
    background-color: black;
    position: relative;
    font-family: Barlow, sans-serif;
    transition: all .8s ease;
  }
  
  .container_content {
   width: 50%;
  }
  
  .container_content_inner {
    width: 80%;
    margin-left: 80px;
  }

  .projects-img-inner {
    display: flex;
    flex-direction: column;
  }
  
  .projects-container-outer-img {
    margin: 128px;
    margin-right: 0px;
    width: 44%;
    overflow: hidden;
    display: inline-block;
    --aug-border: 5px;
    --aug-inset: 5px;
    padding: 60px; /* mind the gap */
    padding-top: 0;
    text-align: center;
    --aug-border-bg:linear-gradient(#b5053b, transparent), linear-gradient(to right, #276d6c, transparent), #276d6c;
  }   
      
  .container_img {
    width: 80%;
    animation: slideIn 1.5s ease-in-out forwards;
  }
  
  .par {
    height: auto;
    overflow: hidden;
  }
  
  p{
    line-height: 28px;
    transform: translateY(300px);
    animation: slideUp .8s ease-in-out forwards .8s;
  }
  
  .btns {
    height: 100%;
    position: relative;
    width: 150px;
    overflow: hidden;
  }
  
  .btns_more {
    background: transparent; 
    padding: 8px 12px;
    color: #BF307F;
    font-size: 16px;
    border:none;
    text-transform: lowercase;
    position: relative;
    margin-top: 15px;
    outline: none;
    transform: translateY(50px);
    animation: slideUp .8s ease-in-out  forwards 1s;

    --aug-border: 5px;
    --aug-inset: 5px;
    padding: 12px; /* mind the gap */
    text-align: center;
    --aug-border-bg:linear-gradient(#b5053b, transparent), linear-gradient(to right, #276d6c, transparent), #276d6c;
  }
  
  .title {
    overflow: hidden;
    height: auto;
  }
  
  .project-header {
      font-size: 40px;
      /* color: var(--teal-color); */
      margin-bottom: 20px;
      transform: translateY(100px);
      animation: slideUp .8s ease forwards .5s;
  }
  
  @keyframes slideIn {
    0% {
      transform: translateX(500px) scale(.2);
    }
    100% {
      transform: translateX(0px) scale(1);
    }
  }
  
  @keyframes slideUp {
    0% {
      transform: translateY(300px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  
  @keyframes expand {
    0% {
      transform: translateX(1400px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  #footer {
    margin-top: 900px;
  }

  .footer-nav {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }



/* CSS FLIP */
 /* entire container, keeps perspective */
.flip-container {
	perspective: 1000px;
}
	/* flip the pane when hovered */
	.flip-container:hover .flipper, .flip-container.hover .flipper {
		transform: rotateY(180deg);
	}

.flip-container  {
	width: 100%;
	height: 40vh;
}

.front.digi-img {
  margin-top: 50px;
}

.back {
  margin: 50px;
}

.back.digi-back {
  margin: 50px;
}

.tallyho-desc.back {
  margin: 20px;
}

/* flip speed goes here */
.flipper {
	transition: 0.6s;
	transform-style: preserve-3d;

	position: relative;
}

/* hide back of pane during swap */
.front, .back {
	backface-visibility: hidden;

	position: absolute;
	top: 0;
	left: 0;
}

/* front pane, placed above back */
.front {
	z-index: 2;
	/* for firefox 31 */
	transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
	transform: rotateY(180deg);
}

.project-img {
  width: 60%;
}

}
