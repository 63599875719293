:root {
    --blue-color: #607aa6; 
    --red-color: #b5053b;
    --maroon-color: #331517;
    --teal-color: #276d6c;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: -10;
  background-color: var(--teal-color);
}

.container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  animation: expand .8s ease forwards;
  background-color: black;
  position: relative;
  font-family: Barlow, sans-serif;
  transition: all .8s ease;
}

.container_content {
 width: 130%;
}

.container_content_inner {
  width: 70%;
  margin-left: 80px;
}

.img_inner {
  margin-right: -63px;
  margin-left: -63px;
}

.container_outer_img {
  margin: 70px;
  width: 44%;
  overflow: hidden;
  display: inline-block;
  --aug-border: 5px;
  --aug-inset: 5px;
  padding: 27px; /* mind the gap */
  text-align: center;
  --aug-border-bg:linear-gradient(#b5053b, transparent), linear-gradient(to right, #276d6c, transparent), #276d6c;
}   
    
.container_img {
  width: 80%;
  animation: slideIn 1.5s ease-in-out forwards;
}

.par {
  height: auto;
  overflow: hidden;
  margin-bottom: 25px;
}

p{
  line-height: 28px;
  transform: translateY(300px);
  animation: slideUp .8s ease-in-out forwards .8s;
}

.btns-container {
  display: flex;
  justify-content: center;
}

.btns {
  height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.btns_more {
  background: transparent; 
  padding: 8px 12px;
  color: var(--red-color);
  font-size: 16px;
  border:none;
  text-transform: lowercase;
  position: relative;
  margin-top: 15px;
  outline: none;
  transform: translateY(50px);
  animation: slideUp .8s ease-in-out  forwards 1s;
}

.icon-button {
  color: var(--red-color);
  margin:10px;
  font-weight: bold;
  position: relative;
  transform: translateY(50px);
  animation: slideUp .8s ease-in-out  forwards 1s;
}

a.icon-button.btns_more:hover {
  color: var(--blue-color);
}

.icon-animation {
  transform: translateY(50px);
  animation: slideUp .8s ease-in-out  forwards 1s;
}

.title {
  overflow: hidden;
  height: auto;
}

h1 {
    font-size: 40px;
    color: var(--teal-color);
    margin-bottom: 20px;
    transform: translateY(100px);
    animation: slideUp .8s ease forwards .5s;
}

@keyframes slideIn {
  0% {
    transform: translateX(500px) scale(.2);
  }
  100% {
    transform: translateX(0px) scale(1);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(300px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes expand {
  0% {
    transform: translateX(1400px);
  }
  100% {
    transform: translateX(0px);
  }
}





/* 800px and above; ipad */

@media screen and (min-width: 700px) {

  .icon-button {
    margin: 2px;
  }

  .container {
    justify-content: space-around;
    flex-direction: unset;
  }
  
  .container_content {
   width: 50%;
  }
  
  .container_content_inner {
    width: 80%;
  }

  .container_outer_img {
    margin: 128px;
  }   

}